import { httpFallbackWarnLogger } from '../logging/fallback';

const acceptHeaders = { Accept: 'application/json' };

const defferAndSummarize = (httpLogger, delay) => {
    let opt;

    let errorReportBuffer = {};
    let errorReportTimeout;

    const _setTimeout = () => {
        if (errorReportTimeout) {
            clearTimeout(errorReportTimeout);
            errorReportTimeout = null;
        }
        errorReportTimeout = setTimeout(_sendErrorBuffer, delay);
    };

    const _sendErrorBuffer = async () => {
        if (errorReportTimeout) {
            clearTimeout(errorReportTimeout);
            errorReportTimeout = null;
        }

        const buff = errorReportBuffer;
        // clear the buffer
        errorReportBuffer = {};

        // exit if no items in buff
        if (!(buff && Object.keys(buff).length)) return;

        // format the message
        let errorSummary = Object.keys(buff)
            .filter(k => buff[k] > 1)
            .map(k => `[${buff[k]}] - ${k}`)
            .reduce((a, b) => `${a}${b}\n`, '');

        if (!errorSummary) return;

        errorSummary = `Error Summary\n${errorSummary}`;
        try {
            await httpLogger(errorSummary, opt);
        } catch {
            // if reporting the error fails restore the buffer and sched a retry
            errorReportBuffer = buff;
            _setTimeout();
        }
    };

    return async (msg, options) => {
        opt = options;
        if (!errorReportBuffer[msg]) {
            errorReportBuffer[msg] = 1;
            await httpLogger(msg, opt);
        } else {
            errorReportBuffer[msg]++;
        }

        _setTimeout();
    };
};

const getPublisherConfig = async key => {
    try {
        const requestOptions = {
            headers: acceptHeaders,
            method: 'GET'
        };

        let url = '/api/nats/PublishConfig';
        const params = { key };
        const searchParams = new URLSearchParams(params).toString();
        url += `?${searchParams}`;

        const resp = await fetch(url, requestOptions);
        const body = await resp.text();
        if (body.length) {
            return JSON.parse(body);
        }

        return Promise.resolve({
            DedupeWindowMs: 0,
            Throttle: 0
        });
    } catch (error) {
        try {
            await httpFallbackWarnLogger(`NATS: Failed to get publish config for ${key} - ${error.toString()}`);
        } finally {
            return Promise.resolve({
                DedupeWindowMs: 0,
                Throttle: 0
            });
        }
    }
};

const getConnectionInfo = async () => {
    try {
        const requestOptions = {
            headers: acceptHeaders,
            method: 'GET'
        };

        let url = 'api/nats';

        const resp = await fetch(url, requestOptions);
        const body = await resp.text();

        return JSON.parse(body);
    } catch (error) {
        await httpFallbackWarnLogger(`NATS: Failed to get connection info - ${error.toString()}`);
    }
};

export { defferAndSummarize, getPublisherConfig, getConnectionInfo };
