import { UAParser } from 'ua-parser-js';

const parser = new UAParser();
const parsed = parser.getResult();

const getOs = () => (parsed.os && parsed.os.name ? `${parsed.os.name}_${parsed.os.version}` : 'unknown').toLowerCase();
const getBrowser = () =>
    (parsed.browser && parsed.browser.name
        ? `${parsed.browser.name}_${parsed.browser.version}`
        : 'unknown'
    ).toLowerCase();
const isMobileApp = () => (parsed.ua ? parsed.ua.includes('FieldLevel') : false);

// Get a formatted client hostname with client OS and browser version
export const getClientHostname = () => {
    const isApp = isMobileApp();
    return `${getOs()}-${getBrowser()}${isApp ? '_app' : ''}`;
};

// Get the raw parsed User-Agent result
export const getRawParsedResult = () => {
    return parsed;
};
