import uuid from 'uuid/v4';

import * as Storage from '@common/storage';
import { getCurrentUtcTimestamp, getSystemConstantFromWindow } from '@common/utils';
import { EMPTY_GUID } from '@common/constants';

import * as nats from './nats-wrapper';
import * as NatsApi from './nats-api';

import { getClientHostname } from '../userAgent';
import { httpFallbackErrorLogger, httpFallbackWarnLogger } from '../logging/fallback';
import { getTagsWithSystemTags } from '../logging/tags';
import { NATS_LOGGING_CHANNEL } from '../constants';

const SERVICE_NAME = 'APP';
const AUTH_PROFILE = getSystemConstantFromWindow('AUTH_PROFILE');

const userGuid = AUTH_PROFILE?.Guid || EMPTY_GUID;

export const TELEMETRY_DATA_TYPE = {
    IMPRESSION: 1,
    CLICK: 2
};

let clientId = 'NA';
let connecting = false;

const callbacks = {};
const hostname = getClientHostname();
const MAX_TIMEOUT_MULTIPLIER = 30;
const RECONNECT_TIMEOUT_MS = 5000;
let reconnectAttempts = 0;
let reconnectTimeoutId = null;

const _retryConnect = () => {
    // if already scheduled do nothing
    if (reconnectTimeoutId) {
        return;
    }

    const timeout_multiple =
        reconnectAttempts <= MAX_TIMEOUT_MULTIPLIER
            ? reconnectAttempts > 0
                ? reconnectAttempts
                : 1
            : MAX_TIMEOUT_MULTIPLIER;

    reconnectTimeoutId = setTimeout(connect, RECONNECT_TIMEOUT_MS * timeout_multiple);
};

const connect = async () => {
    try {
        if (reconnectTimeoutId) {
            clearTimeout(reconnectTimeoutId);
            reconnectTimeoutId = null;
        }

        if (!nats.getIsConnected()) {
            if (!connecting) {
                connecting = true;
                reconnectAttempts++;

                const userInfo = await NatsApi.getConnectionInfo();

                if (!userInfo) {
                    throw new Error('NATS: Failed to obtain connection settings!');
                }

                if (!!userInfo.ClientId) clientId = userInfo.ClientId;

                const conf = JSON.parse(atob(userInfo.Token));

                await nats.connect(conf);
            }
        }

        if (nats.getIsConnected()) {
            connecting = false;
            reconnectAttempts = 0;

            _drainBuffer();
        } else {
            _retryConnect();
        }
    } catch (err) {
        connecting = false;
        _retryConnect();

        // attempts to reduce noise. note: potential to loose nuance if different errors occur inside retry window
        if (reconnectAttempts == 0 || reconnectAttempts % MAX_TIMEOUT_MULTIPLIER == 0) {
            _httpLogErrorAndSummarize(`NATS: failed to connect! ${err.toString()}`);
        }
    }
};

const _hash32 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    const hashNum = 4294967296 * (2097151 & h2) + (h1 >>> 0);
    return hashNum.toString(32);
};

// for error messages, system fields are tags
const _addSystemFieldsAsTags = payload => {
    const tags = (payload && payload.Tags) || [];
    const tagsWithSystemTags = getTagsWithSystemTags({ tags, clientId, userGuid });
    payload.ClientId = SERVICE_NAME;
    payload.Tags = tagsWithSystemTags;
};

// for telemetry messages, system fields are on the payload itself
const _addSystemFields = payload => {
    payload.ClientId = clientId;
    payload.UserGuid = userGuid;
};

const OFFLINE_BUFFER_TTL = 3600; // 1h expiry on how long we store offline msgs
const OFFLINE_BUFFER_KEY = 'nats-offline-buffer-key';

const _handleOfflinePublish = (channel, payload) => {
    let offlineBuffer = Storage.get(OFFLINE_BUFFER_KEY);
    if (!offlineBuffer) {
        offlineBuffer = [];
    }

    offlineBuffer.push({ channel, payload });

    Storage.set(OFFLINE_BUFFER_KEY, offlineBuffer, OFFLINE_BUFFER_TTL);

    if (!reconnectTimeoutId) {
        _retryConnect();
    }
};

const _drainBuffer = () => {
    let offlineBuffer = Storage.get(OFFLINE_BUFFER_KEY);
    Storage.set(OFFLINE_BUFFER_KEY, [], OFFLINE_BUFFER_TTL);
    while (offlineBuffer && offlineBuffer.length > 0) {
        const m = offlineBuffer.shift();
        _publish(m.channel, m.payload);
    }
};

const _publish = (channel, payload) => {
    try {
        if (nats.getIsConnected()) {
            // add system fields as late as possible before we actually publish the message
            if (channel === NATS_LOGGING_CHANNEL) {
                _addSystemFieldsAsTags(payload);
            } else {
                _addSystemFields(payload);
            }

            nats.publish(channel, payload);
        } else {
            _handleOfflinePublish(channel, payload);
        }
    } catch (err) {
        _handleOfflinePublish(channel, payload);
        _httpLogErrorAndSummarize(`NATS: failed to publish! ${err.toString()}`);
    }
};

const publish = async (channel, data, flatten = false) => {
    let payload = {
        MessageId: uuid(),
        Hostname: hostname,
        EmittedDateTime: getCurrentUtcTimestamp(),
        MessageType: data.MessageType
    };

    if (data.MessageType) {
        delete data.MessageType;
    }

    if (flatten) {
        payload = Object.assign({}, payload, data);
    } else {
        payload.Data = data;
    }

    if (reconnectTimeoutId) {
        _handleOfflinePublish(channel, payload);
    } else {
        await connect();
        _publish(channel, payload);
    }
};

const request = async (channel, data) => {
    try {
        await connect();
        return await nats.request(channel, data);
    } catch (err) {
        httpLogError(err.toString());
    }
};

const getCallback = (channel, onMessage) => {
    // we'll keep a ref to all registered callbacks

    let isNew = false;
    if (!callbacks[channel]) {
        callbacks[channel] = [];
        // we've never seen this channel before
        isNew = true;
    }

    // add cb if we don't have it.
    if (callbacks[channel].indexOf(onMessage) < 0) {
        callbacks[channel].push(onMessage);
    }

    // get a callback that calls all registered callbacks
    return { isNew, cb: data => callbacks[channel].forEach(cb => cb(data)) };
};

const subscribe = async (channel, onMessage) => {
    try {
        await connect();

        const o = getCallback(channel, onMessage);

        // only subscribe once.
        if (o.isNew) {
            await nats.subscribe(channel, o.cb);
        }
    } catch (err) {
        httpLogError(err.toString());
    }
};

const unsubscribe = async (channel, callback) => {
    try {
        // check if we have anything registered for this channel
        if (callbacks && callbacks[channel]) {
            // check if this cb was registered.
            const cbs = callbacks[channel];
            if (cbs.indexOf(callback) >= 0) {
                // remove it.
                const newCbs = cbs.filter(x => x != callback);
                if (newCbs.length > 0) {
                    callbacks[channel] = newCbs;
                }
                // if we have no more callbacks, unsubscribe.
                else {
                    delete callbacks[channel];
                    await nats.unsubscribe(channel);
                }
            }
        }
    } catch (err) {
        httpLogError(`NATS: failed to remove subscription for ${channel} -- ${err.toString()}`);
    }
};

const getRequestClient = channel => data => request(channel, data);

const publishers = {};

// throttle = msg per minute. 0 = no throttle
const getTelemetryPublisher = ({ telemetryType, flatten = false, throttle = 0, dedupeWindowMs = 0 }) => {
    return getPublisher({
        channel: `browser.telemetry${telemetryType ? `.${telemetryType}` : ''}`,
        flatten,
        throttle,
        dedupeWindowMs
    });
};

// throttle = msg per minute. 0 = no throttle
const getPublisher = ({ channel, flatten = false, throttle = 0, dedupeWindowMs = 0 }) => {
    // cache the publisher in case another component needs it.
    const pKey = `${channel}${throttle}${dedupeWindowMs}${flatten ? 1 : 0}`;
    let p = publishers[pKey];

    if (!p) {
        // TODO: emit those counts as stats.
        let duplicatesDropped = 0;
        let overQuotaMessagesDropped = 0;

        const dedupeReg = {};
        const dedupeCleanUp = () => {
            Object.keys(dedupeReg).forEach(k => {
                const now = Date.now();
                if (now - dedupeReg[k] > dedupeWindowMs) {
                    delete dedupeReg[k];
                }
            });

            // repeat every 30s
            setTimeout(dedupeCleanUp, 30000);
        };
        if (dedupeWindowMs > 0) {
            dedupeCleanUp();
        }

        const throttleWindow = 60 * 1000;
        let count = 0;
        let lastUpdate = Date.now();

        p = async data => {
            const now = Date.now();

            if (dedupeWindowMs > 0) {
                const hash = _hash32(JSON.stringify(data));
                if (!dedupeReg[hash] || now - dedupeReg[hash] > dedupeWindowMs) {
                    dedupeReg[hash] = now;
                } else {
                    // seen this before, drop it.
                    duplicatesDropped++;
                    return;
                }
            }

            if (throttle > 0) {
                // throttle [messages/minute]
                if (++count > throttle) {
                    // reset timer and count if a minute had passed
                    if (now - lastUpdate > throttleWindow) {
                        lastUpdate = now;
                        count = 1;
                    } else {
                        // over the quota, drop it.
                        overQuotaMessagesDropped++;
                        return;
                    }
                }
            }

            data.MessageType = 3;
            await publish(channel, data, flatten);
        };

        publishers[pKey] = p;
    }

    return p;
};

let _errorSummaryLogger;
const _httpLogErrorAndSummarize = async msg => {
    if (!_errorSummaryLogger) {
        _errorSummaryLogger = NatsApi.defferAndSummarize(
            async (msg, opt) => await httpFallbackWarnLogger(msg, opt),
            RECONNECT_TIMEOUT_MS * MAX_TIMEOUT_MULTIPLIER
        );
    }
    await _errorSummaryLogger(msg, { clientId, userGuid });
};

const httpLogError = async msg => await httpFallbackErrorLogger(msg, false, { clientId, userGuid });

const getPublisherConfig = async key => await NatsApi.getPublisherConfig(key);

export {
    connect,
    publish,
    request,
    subscribe,
    unsubscribe,
    getPublisher,
    getTelemetryPublisher,
    getRequestClient,
    getPublisherConfig
};
