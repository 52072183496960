import { CLIENT_VERSION } from '@common/constants';

const VERSION_TAG_NAME = 'Version';
const CLIENT_ID_TAG_NAME = 'FlClientId';
const USER_GUID_TAG_NAME = 'UserGuid';

const _hasTag = (tags, tag) => {
    if (tags && tags.length) {
        return tags.some(t => t.includes(`${tag}:`));
    }

    return false;
};

const getTagsWithSystemTags = ({ tags, clientId, userGuid }) => {
    const newTags = (tags && tags.slice(0)) || [];

    if (!_hasTag(tags, VERSION_TAG_NAME)) {
        newTags.push(`${VERSION_TAG_NAME}:${CLIENT_VERSION}`);
    }

    if (clientId && !_hasTag(tags, CLIENT_ID_TAG_NAME)) {
        newTags.push(`${CLIENT_ID_TAG_NAME}:${clientId}`);
    }

    if (userGuid && !_hasTag(tags, USER_GUID_TAG_NAME)) {
        newTags.push(`${USER_GUID_TAG_NAME}:${userGuid}`);
    }

    return newTags;
};

export { getTagsWithSystemTags };
