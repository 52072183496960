import uuid from 'uuid/v4';
import { getCurrentUtcTimestamp } from '@common/utils';

import { getClientHostname } from '../userAgent';
import { getTagsWithSystemTags } from './tags';

const acceptHeaders = { Accept: 'application/json' };

const httpFallbackLogger = async (msg, logLevel, logPriority, options) => {
    const clientId = options?.clientId;
    const userGuid = options?.userGuid;

    try {
        const logItem = {
            MessageId: uuid(),
            Hostname: getClientHostname(),
            EmittedDateTime: getCurrentUtcTimestamp(),
            MessageType: 3, // Telemetry
            ClientId: 'APP',
            LogLevel: logLevel,
            LogPriority: logPriority,
            Message: msg,
            Tags: getTagsWithSystemTags({ tags: [], clientId, userGuid })
        };

        const requestOptions = {
            method: 'POST',
            headers: { ...acceptHeaders, 'Content-Type': 'application/json' },
            body: JSON.stringify(logItem)
        };

        await fetch('/api/nats/FallbackLogItem', requestOptions);
    } catch (error) {
        console.error(error);
    }
};

const httpFallbackInfoLogger = async (msg, options) => {
    await httpFallbackLogger(
        msg,
        0, // INFO
        1, // LOW
        options
    );
};

const httpFallbackWarnLogger = async (msg, options) => {
    await httpFallbackLogger(
        msg,
        1, // WARN
        1, // LOW
        options
    );
};

const httpFallbackErrorLogger = async (msg, isCritical, options) => {
    await httpFallbackLogger(
        msg,
        isCritical ? 3 : 2, // CRITICAL, ERROR
        isCritical ? 4 : 3, // VeryHigh, High
        options
    );
};

export { httpFallbackInfoLogger, httpFallbackWarnLogger, httpFallbackErrorLogger };
