import { getTelemetryPublisher, getPublisherConfig } from '../nats/nats';

let publisher;
let legacyPublisher;

const retryInterval = 5000;
let nRetries = 1;
let nRetriesLegacy = 1;

const maxRetryEvents = 1000;
const maxRetryAttempts = 10;
const eventBuffer = [];
const legacyEventBuffer = [];

const retryEvents = (buffer, retryAttempts) => {
    if (retryAttempts >= maxRetryAttempts) {
        buffer.length = 0;
        return;
    }

    const todo = [...buffer];
    buffer.length = 0;

    while (todo.length > 0) {
        logEvent(todo.pop());
    }
};

const addRetryEvent = (payload, buffer) => {
    if (buffer.length >= maxRetryEvents) {
        return;
    }

    buffer.push(payload);
};

// configure publisher.
const getLegacyPublisher = () => {
    const publisherConfigKey = 'NatsAnalytics-browser.telemetry.legacy';

    if (!legacyPublisher) {
        getPublisherConfig(publisherConfigKey).then(conf => {
            //console.log(conf);
            legacyPublisher = getTelemetryPublisher({
                telemetryType: 'legacy',
                flatten: true,
                dedupeWindowMs: conf.DedupeWindowMs,
                throttle: conf.Throttle
            });

            if (legacyPublisher && legacyEventBuffer.length) {
                retryEvents(legacyEventBuffer, nRetriesLegacy);
            }
        });

        setTimeout(getLegacyPublisher, nRetriesLegacy++ * retryInterval);
    }
};

const getPublisher = () => {
    const publisherConfigKey = 'NatsAnalytics-browser.telemetry.analytics';

    if (!publisher) {
        getPublisherConfig(publisherConfigKey).then(conf => {
            //console.log(conf);
            publisher = getTelemetryPublisher({
                telemetryType: 'analytics',
                flatten: true,
                dedupeWindowMs: conf.DedupeWindowMs,
                throttle: conf.Throttle
            });

            if (publisher && eventBuffer.length) {
                retryEvents(eventBuffer, nRetries);
            }
        });

        setTimeout(getPublisher, nRetries++ * retryInterval);
    }
};

getPublisher();
getLegacyPublisher();

const AnalyticsTypeEnum = {
    Unknown: 'Unknown',
    Google: 'Google',
    Impression: 'Impression',
    Click: 'Click',
    PageView: 'PageView'
};

/*
 payload shape
 {
     Type: AnalyticsTypeEnum,
     Category: "event category",
     Action: "The action performed",
     Label: "Any label to attach to event",
     Value: number - any numeric data describing the event,
     Path: /page/path, - add query params if relevant to Data
     Data: {
         OptionalField1: "some data"
         OptionalField2 "some data"
     }
 }
*/
const logEvent = async (
    payload = {
        Type,
        Category,
        Action,
        Label: null,
        Value: 0,
        Path: null,
        Data: undefined
    }
) => {
    if (!payload.Type) {
        payload.Type = AnalyticsTypeEnum.Unknown;
    }

    if (publisher) {
        await publisher(payload);
    } else {
        addRetryEvent(payload, eventBuffer);
    }
};

const logEventLegacy = async (
    payload = { Type, Category, Action, Label: null, Value: 0, Path: null, Data: undefined }
) => {
    if (!payload.Type) {
        payload.Type = AnalyticsTypeEnum.Unknown;
    }

    if (legacyPublisher) {
        await legacyPublisher(payload);
    } else {
        addRetryEvent(payload, legacyEventBuffer);
    }
};

export { logEvent, logEventLegacy, AnalyticsTypeEnum };
