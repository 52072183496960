import { connect as connectNats, StringCodec, ErrorCode } from 'nats.ws';

let connection = undefined;
let subscriptions = {};

const connect = async conf => {
    const conn = await connectNats({
        servers: conf.Servers,
        user: conf.User,
        pass: conf.Pass,
        verbose: false,
        debug: false,
        ignoreClusterUpdates: conf.IgnoreClusterUpdates
        // authenticator: () => ({
        //   user: "",
        //   pass: "",
        // }),
    });

    connection = conn;
};

const getIsConnected = () => {
    return connection && connection.protocol && connection.protocol.connected;
};

const publish = (channel, payload) => {
    const sc = StringCodec();
    connection.publish(channel, sc.encode(JSON.stringify(payload)));
};

const request = async (channel, data) => {
    try {
        const m = await connection.request(channel, data);
        return m.data;
    } catch (err) {
        switch (err.code) {
            case ErrorCode.NO_RESPONDERS:
                throw new Error(`NATS: no one is listening to ${channel} --  ${err.toString()}`);
            case ErrorCode.TIMEOUT:
                throw new Error(`NATS: request timeout ${err.toString()}`);
            default:
                throw new Error(`NATS: request failed ${err.toString()}`);
        }
    }
};

const subscribe = async (channel, onMessage) => {
    if (subscriptions[channel]) {
        throw new Error(`NATS: subscription already exists for ${channel}`);
    }

    const sub = connection.subscribe(channel);

    subscriptions[channel] = sub;

    const sc = StringCodec();
    (async () => {
        for await (const m of sub) {
            const data = JSON.parse(sc.decode(m.data));
            onMessage(data);
        }

        throw new Error(`NATS: subscription closed for ${channel}`);
    })();
};

const unsubscribe = async channel => {
    try {
        const sub = subscriptions[channel];
        if (sub) {
            await sub.drain();
            delete subscriptions[channel];
        }
    } catch (err) {
        throw new Error(`NATS: subscription notfound for ${channel}`, err);
    }
};

const drain = async () => {
    try {
        await connection.drain();
    } catch (err) {
        throw new Error(`NATS: Failed during drain`, err);
    }
};

export { connect, publish, subscribe, unsubscribe, request, drain, getIsConnected };
