// Set a value in localStorage with a given key and time-to-live in seconds
export const set = (keyName, keyValue, ttl) => {
    const data = {
        value: keyValue,
        ttl: Date.now() + ttl * 1000
    };
    localStorage.setItem(keyName, JSON.stringify(data));
};

// Get a value from localStorage with the given key, or null if the time-to-live has exceeded
export const get = keyName => {
    const data = localStorage.getItem(keyName);
    if (!data) {
        return null;
    }

    const item = JSON.parse(data);

    if (item.ttl && Date.now() > item.ttl) {
        localStorage.removeItem(keyName);
        return null;
    }

    return item.value;
};
