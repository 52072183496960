import { onLCP, onCLS, onTTFB } from 'web-vitals';

import * as Storage from '@common/storage';
import { getSystemConstantFromWindow } from '@common/utils';

import { getTelemetryPublisher } from '../nats/nats';
import { logError } from '../logging/NatsLogger';

let publisher;
let nRetries = 1;
let initialPage = 'NA';

const retryInterval = 5000;
const AUTH_PROFILE = getSystemConstantFromWindow('AUTH_PROFILE');
const WEB_VITALS_THROTTLE = getSystemConstantFromWindow('WEB_VITALS_THROTTLE');
const WEB_VITALS_STORAGE_KEY = 'web-vitals-enabled';
const WEB_VITALS_STORAGE_TTL = 24 * 60 * 60; // 24 hours

const queue = new Set();
const addToQueue = metric => {
    queue.add(metric);
};

const buildPerformancePayload = metrics => {
    const payload = {};

    const lcp = metrics.find(m => m.name === 'LCP');
    payload.LCP = lcp?.value || -1;

    const cls = metrics.find(m => m.name === 'CLS');
    payload.CLS = cls?.value || -1;

    const ttfb = metrics.find(m => m.name === 'TTFB');
    payload.TTFB = ttfb?.value || -1;

    payload.InitialPage = initialPage;
    payload.Authenticated = AUTH_PROFILE.IsAuthenticated;

    return payload;
};

const flushQueue = () => {
    if (queue.size > 0) {
        const payload = buildPerformancePayload([...queue]);
        logPerformanceEvent(payload);

        queue.clear();
    }
};

const getPublisher = () => {
    if (!publisher) {
        publisher = getTelemetryPublisher({
            telemetryType: 'web_vitals',
            flatten: true
        });
    }

    setTimeout(getPublisher, nRetries++ * retryInterval);
};

/*
 payload shape
 {
    LCP: number - Largest Contentful Paint measurement
    CLS: number - Cumulative Layout Shift measurement
    TTFB: number - Time to First Byte measurement
    InitialPage: string - Pathname of initially loaded page
    Authenticated: boolean - Is authenticated session
 }
*/
const logPerformanceEvent = async (
    payload = {
        LCP,
        CLS,
        TTFB,
        InitialPage
    }
) => {
    if (publisher) {
        await publisher(payload);
    } else {
        logError('Bootstrap: No publisher available for logPerformanceEvent');
    }
};

const webVitalsEnabled = () => {
    const webVitalsThrottle = parseFloat(WEB_VITALS_THROTTLE);
    if (isNaN(webVitalsThrottle) || webVitalsThrottle > 1) {
        logError(`Bootstrap: Invalid web vitals throttle: ${webVitalsThrottle}`);
        return false;
    }

    if (webVitalsThrottle > 0) {
        const previousSelection = Storage.get(WEB_VITALS_STORAGE_KEY);
        if (previousSelection !== null) return previousSelection;

        const rand = Math.random();
        if (rand < webVitalsThrottle) {
            Storage.set(WEB_VITALS_STORAGE_KEY, true, WEB_VITALS_STORAGE_TTL);
            return true;
        }

        Storage.set(WEB_VITALS_STORAGE_KEY, false, WEB_VITALS_STORAGE_TTL);
    }

    return false;
};

const init = () => {
    if (!webVitalsEnabled()) {
        return;
    }

    onCLS(addToQueue);
    onLCP(addToQueue);
    onTTFB(addToQueue);

    getPublisher();
    initialPage = window.location && window.location.pathname;

    // Report all available metrics whenever the page is backgrounded or unloaded.
    addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
            flushQueue();
        }
    });

    // NOTE: Safari does not reliably fire the `visibilitychange` event when the page is being unloaded so we do it on `pagehide`.
    addEventListener('pagehide', flushQueue);
};

export { init };
